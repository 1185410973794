.panel-h-main {
  position: absolute;
  z-index: 1000;
  background-color: #FFFF;
}
.open {
  height: 100vh;
  width: 50vw;
  left: 0;
  border-right: 3px solid black;

  padding: 1em;
  transition: left 0.2s ease-out;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  a {
    font-size: 10vw;
    background-color: transparent;
    text-decoration: none;
    cursor: pointer;
  }
  a:hover{
    color: #073488;
  }
}
.close {
  left: -25em;
  transition: left 0.2s ease-out;
}
