footer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 4vh;
  font-size: 0.7em;
  color: #454545;
}