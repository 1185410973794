@import "normalize.css";
@import "styles/styles";
@import url('https://fonts.googleapis.com/css2?family=Oxygen&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Changa+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Paytone+One&display=swap');
body {
  margin: 0;
  font-family: 'Paytone One', sans-serif;
  color: $primaryFontColor;

  .home {
    background-color: #219ce8;
    color: darken(#073488, 20%);

    a {
      text-decoration: none;
      font-size: 1.5em;
    }

    a:hover {
      cursor: pointer;
      color: aliceblue;
    }
  }
  .contact {
    background-color: #76BA99;
    color: #454545;

    a {
      text-decoration: none;
      font-size: 1.5em;
    }

    a:hover {
      cursor: pointer;
      color: aliceblue;
    }
  }
  .formule {
    background-color: #e8c978;
    color: #454545;

    a {
      text-decoration: none;
      font-size: 1.5em;
    }

    a:hover {
      cursor: pointer;
      color: aliceblue;
    }
  }
  .infos {
    background-color: #bf78e8;
    color: #454545;

    a {
      text-decoration: none;
      font-size: 1.5em;
    }

    a:hover {
      cursor: pointer;
      color: aliceblue;
    }
  }
}
