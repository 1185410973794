header {
  display: flex;
  position: fixed;
  top: 0;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: 8vh;

  .h1 {
    margin-left: 0.5em;
    font-size: 3.5vw;
  }

  ul {
    margin-right: 1em;
    list-style: none;
    display: flex;

    li {
      margin-right: 1em;
      cursor: pointer;
    }
  }

  .hamburger {
    display: none;
    margin-right: 1em;
  }

  @media only screen and (max-width: 50em) {
    ul {
      display: none;
    }
    .hamburger {
      display: block;
    }
    .h1{
      font-size: 1.5em;
    }
  }
}

